<template>
  <div class="position-relative">
    <div class="legend" :id="'legend_' + id" :style="'--id:' + id"></div>
    <div class="position-relative chart-container">
      <canvas :id="id" height="300"></canvas>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js";

export default {
  props: {
    id: {
      required: true,
      type: String,
    },
    chartData: {
      required: true,
      type: Array,
    },
    label: {
      required: true,
      type: Array,
    },
    text: {
      required: false,
    },
    hideLegend: {
      required: false,
      default: false,
      type: Boolean,
    },
  },

  mounted() {
    this.initChart();
  },
  methods: {
    addCommas(nStr) {
      var x, x1, x2;

      nStr += "";
      x = nStr.split(".");
      x1 = x[0];
      x2 = x.length > 1 ? "." + x[1] : "";
      var rgx = /(\d+)(\d{3})/;
      while (rgx.test(x1)) {
        x1 = x1.replace(rgx, "$1" + "," + "$2");
      }
      return x1 + x2;
    },
    initChart() {
      var ctx = document.getElementById(this.id);
      var that = this;

      var newLegendClickHandler = function (e, legendItem) {
        var index = legendItem.datasetIndex;
        var ci = this.chart;
        var meta = ci.getDatasetMeta(index);

        meta.hidden =
          meta.hidden === null ? !ci.data.datasets[index].hidden : null;

        // We hid a dataset ... rerender the chart
        ci.update();
      };
      var myChart = new Chart(ctx, {
        type: "bar",
        data: {
          labels: this.label,
          datasets: this.chartData,
        },
        options: {
          tooltips: {
            mode: "index",
            intersect: false,
            callbacks: {
              title: function (tooltipItem, data) {
                return `${data.labels[tooltipItem[0].index]}`;
              },
              label: function (tooltipItem, data) {
                var datasetLabel =
                  data.datasets[tooltipItem.datasetIndex].label || "Other";

                return (
                  datasetLabel +
                  " - Used : " +
                  that.addCommas(tooltipItem.value)
                );
              },
            },
          },
          hover: {
            mode: "index",
            intersect: true,
          },
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            onClick: newLegendClickHandler,
            display: !this.hideLegend,
            align: "start",
            position: "bottom",
            padding: 25,
            labels: {
              fontSize: 10,
              boxWidth: 12,
              boxHeight: 12,
              fontFamily: "Kanit-Regular",
              fontColor: "black",

              fullWidth: false,
            },
          },

          scales: {
            yAxes: [
              {
                // gridLines: {
                //   display: false,
                // },
                // stacked: true,
                scaleLabel: {
                  display: true,
                  fontStyle: "bold",
                  labelString: this.text || "",
                  fontFamily: "Kanit-Regular",
                },
                ticks: {
                  // autoSkip: true,
                  suggestedMin: 0,
                  stepSize: 1,
                  callback: function (label, index, labels) {
                    return that.addCommas(label);
                  },
                },
              },
            ],
            xAxes: [
              {
                gridLines: {
                  display: true,
                },

                scaleLabel: {
                  display: true,
                  labelString: "Date / Time",
                  fontStyle: "bold",
                  fontFamily: "Kanit-Regular",
                },
                ticks: {
                  maxRotation: 0,
                  minRotation: 0,
                  autoSkip: false,

                  min: 0,
                },
              },
            ],
          },
        },
      });
    },
  },
};
</script>

<style lang="scss">
.legend {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
}
// .chart-container {
//   margin-right: 100px;
// }
ul {
  padding-inline-start: 0px !important;
}
@media screen and (max-width: 600px) {
  .chart-container {
    margin-bottom: 20px;
    margin-right: unset;
  }
  .legend {
    bottom: 0;
    right: 50%;
    top: 100%;

    transform: translate(50%, -20px);
    ul {
      flex-direction: row !important;
      gap: 5px;
      li {
        margin-bottom: 0 !important;
      }
      span {
        font-size: 9px !important;
        width: max-content;
        max-width: 80px !important;
      }
    }
  }
}
.chart-container {
  // max-height: 350px;
  height: 450px;
}
</style>
